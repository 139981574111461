import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";
// import moment from "moment"

let configs = require("@/masterConfigs.js").configs;
/*eslint-disable */
// let serverAPI = "" + coreGetServerHost() + "/"
/*eslint-enable */
// console.log("Server API " + serverAPI)

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    name: localStorage.getItem("uuse") || "",
    userId: localStorage.getItem("uedi") || "",
    userAvatar: localStorage.getItem("aatasz-dgbfdkj") || "",
    token: localStorage.getItem("ttk") || "",
    rememberMe: localStorage.getItem("aremsz-hdsjsd") || "",
    loginAt: localStorage.getItem("aloatsz-kjfkksn") || "",
    level: localStorage.getItem("leus") || "",
    mute: localStorage.getItem("teum") || "",
    //notiBlackList: localStorage.getItem("blnoti") || "[]",
    notiBlackList: JSON.parse(
      localStorage.getItem("blnoti") ? localStorage.getItem("blnoti") : "[]"
    ),
  },
  getters: {
    getFullName: (state) => {
      // console.log("Getter Name: ", state.name);
      return state.name;
    },
    userId: (state) => {
      return state.userId;
    },
    token: (state) => {
      return state.token;
    },
    userAvatar: (state) => {
      return state.userAvatar;
    },
    rememberMe: (state) => {
      return state.rememberMe;
    },
    loginAt: (state) => {
      return state.loginAt
    },
    mute: (state) => {
      return state.mute;
    },
    getLevel: (state) => {
      return state.level;
    },
    allowControl: (state) => {
      if (
        configs.controlRoles &&
        state.level &&
        configs.controlRoles.includes(state.level)
      )
        return true;
      return false;
    },
    adminRole: (state) => {
      if (
        configs.adminRoles &&
        state.level &&
        configs.adminRoles.includes(state.level)
      )
        return true;
      return false;
    },
    notiBlackList: (state) => {
      if (!state.notiBlackList) return [];
      return state.notiBlackList;
    },
  },
  mutations: {
    addUser(state, payload) {
      state.userId = payload.userId;
      state.token = payload.token;
      state.name = payload.name;
      state.userAvatar = payload.avatar;
      state.level=  payload.level;
      state.rememberMe = payload.rememberMe;
    },
    logOut(state) {
      state.name = "";
      state.userId = "";
      state.userAvatar = "";
      state.token = "";
      state.rememberMe = "";
      state.level = "";
    },
    fetchUserId: (state, userId) => {
      state.userId = userId;
    },
    muteAlarm(state) {
      state.mute = "1";
    },
    unmuteAlarm(state) {
      state.mute = "0";
    },
    addNotiToBlackList(state, payload) {
      if (!state.notiBlackList) {
        state.notiBlackList = [];
      }
      if (!state.notiBlackList.includes(payload.name)) {
        state.notiBlackList.push(payload.name);
      }
      localStorage.setItem("blnoti", JSON.stringify(state.notiBlackList));
    },
    removeNotiToBlackList(state, payload) {
      if (!state.notiBlackList) {
        state.notiBlackList = [];
      }
      state.notiBlackList = state.notiBlackList.filter(
        (name) => name != payload.name
      );
      localStorage.setItem("blnoti", JSON.stringify(state.notiBlackList));
    },
  },
  actions: {
    muteAlarm: (context) => {
      context.commit("muteAlarm");
      localStorage.setItem("teum", "1");
    },
    unmuteAlarm: (context) => {
      context.commit("unmuteAlarm");
      localStorage.setItem("teum", "0");
    },
    save: (context, payload) => {
      return new Promise((resolve) => {
        context.commit("addUser", {
          name: payload.name,
          level: payload.level,
          userId: payload.userId,
          token: payload.token,
        });
        localStorage.setItem("uuse", payload.name);
        localStorage.setItem("uedi", payload.userId);
        localStorage.setItem("leus", payload.level);
        localStorage.setItem("ttk", payload.token);
        resolve({ status: "OK" });
      });
    },
    saveUserInfo: (context, payload) => {
      context.commit("addUser", {
        userId: payload.userId,
        token: payload.token,
        level: payload.role,
        name: payload.fullname,
        avatar: payload.avatar,
        rememberMe: payload.rememberMe
      });

      localStorage.setItem("uuse", payload.fullname);
      localStorage.setItem("uedi", payload.userId);
      localStorage.setItem("aatasz-dgbfdkj", payload.avatar);
      localStorage.setItem("aremsz-hdsjsd", payload.rememberMe);
      localStorage.setItem("leus", payload.role);
      localStorage.setItem("ttk", payload.token);
    },
    logout: (context) => {
      return new Promise((resolve /* reject */) => {
        context.commit("logOut");
        localStorage.removeItem("kec_ginol");
        localStorage.removeItem("uuse");
        localStorage.removeItem("uedi");
        localStorage.removeItem("aatasz-dgbfdkj");
        localStorage.removeItem("aremsz-hdsjsd");
        localStorage.removeItem("leus");
        localStorage.removeItem("ttk");
        resolve();
      });
    },
    fetchUserId: (context) => {
      context.commit("fetchUserId", localStorage.getItem("uedi"));
    },
    isAllowControl: (context) => {
      return new Promise((resolve, reject) => {
        if (
          configs.controlRoles &&
          context.state.level &&
          configs.controlRoles.includes(context.state.level)
        ) {
          resolve();
        }
        reject();
      });
    },
    /*
    setLogUser(context, payload) {
      let dataLog = {
        logs: [
          {
            desc: payload.desc,
            type: "UserModuleLog",
            deviceId: "masterDev",
            subDeviceName: "UserManager",
            byMan: true,
            pointNames: [],
            date: payload.date,
            tags: payload.tags,
            manName: payload.manName,
            userId: payload.userId,
            extra: "{}",
          },
        ],
      };
      axios
        .post(serverAPI + "logs", dataLog, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("Respond Logs: ", response);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    */
    /*
      Payload = {
        desc: 'string',
        deviceId: "masterDev",
        subDeviceName: "UserManager",
        pointNames: [],
        extra: string json
      }
    */
   /*
    setLogControl(context, payload) {
      return new Promise((resolve, reject) => {
        let apiPayload = {
          logs: [
            {
              desc: payload.desc,
              type: "PointControl",
              deviceId: payload.deviceId,
              subDeviceName: payload.subDeviceName,
              byMan: true,
              pointNames: payload.pointNames,
              date: new moment().format("YYYY-MM-DD HH:mm:ss"),
              tags: "control",
              manName: context.state.name,
              userId: context.state.userId,
              extra: payload.extra,
            },
          ],
        };
        axios
          .post(serverAPI + "logs", apiPayload)
          .then((res) => {
            resolve(res.data.status);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    */
    addNotiToBlackList: (context, payload) => {
      return new Promise((resolve) => {
        context.commit("addNotiToBlackList", payload);
        resolve({ status: "OK" });
      });
    },
    removeNotiFromBlackList: (context, payload) => {
      return new Promise((resolve) => {
        context.commit("removeNotiToBlackList", payload);
        resolve({ status: "OK" });
      });
    },
  },
  modules: {},
});
