
function setupComponents(Vue) {
   Vue.component("pn-header", () => import('../components/core/Header.vue'))

   Vue.component("position-layout", () => import('../components/templates/PositionLayout.vue'))
   Vue.component("stack-layout", () => import('../components/templates/StackLayout.vue'))
   Vue.component("setting-layout", () => import('../components/templates/SettingLayout.vue'))
   
   Vue.component("spacer-widget", () => import('../components/widgets/SpacerWidget.vue'))
   Vue.component("newline-widget", () => import('../components/widgets/NewlineWidget.vue'))
   Vue.component("chart-widget", () => import('../components/widgets/ChartWidget.vue'))
   Vue.component("html-widget", () => import('../components/widgets/HtmlWidget.vue'))
   Vue.component("camera-stream-widget", () => import('../components/widgets/CameraStream.vue'))

   Vue.component("date-picker", () => import('../components/atom/DatePicker.vue'))
   Vue.component("parking-access-item", () => import('../components/atom/ParkingAccessItem.vue'))
}

export { setupComponents }