<template>
  <v-app id="v-app"
    :class='{
      "landscape": rotate_angle==0||rotate_angle==180,
      "portrait": rotate_angle==-90||rotate_angle==90,
    }'>
    <div id="app" >

      <div class="headerHeight"
        style="z-index:10000;position:absolute;top:0;left:0;right:0;background-color:#37474F;">
        <pn-header style="height:100%;"/>
      </div>

      <div  v-if="1==2"
       style="z-index:1000;position:absolute;bottom:0;left:0;">
        <v-btn to="/parking" class="px-1" style="min-width:30px;">
          P </v-btn>
      </div>

      <div v-if="1==2 && kbVisible && ($root.isElectron || $root.virtualKeyboard)"
        class="pt-2 pb-2 px-4 text-center"
        style="z-index:1000;position: absolute;bottom:0;left:0;right:0;background-color:#DDDDDD;">

        <vue-touch-keyboard
          style="margin:auto;"
          :style="{'max-width': kbLayout=='numeric'?'800px':'none'}"
          id="keyboard" 
          :options="kbOptions" :layout="kbLayout" 
          :cancel="kbHide" :accept="kbAccept" :next="kbNext"
          :input="kbInput"/>
      </div>

      <div id="divError" v-if="notify_message.length>0">
        <v-row style="height:100%;width:100%;" justify="center" align="center">
          <v-col style="background-color:#FFFFFF22;margin:auto;max-width:80vmin;padding:5vmin;text-align:center;font-size:4.5vmin;font-weight:500;"
            :style="{color: message_color}">
            {{notify_message}}
          </v-col>
        </v-row>
      </div>

      <div id="content">
        <v-content style="width:100%;height:100%;">
          <router-view />
        </v-content>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
    data () {
      return {
        rotate_angle: 0,
        notify_message: "",
        message_color: 'red',
        activePageTitle: 'Home',
        setterPoint: {},
        setterDialog: false,
        // keyboard --------------
        kbOptions: {
          useKbEvents: false,
          preventClickEvent: false
        },
        kbLayout: "normal",
        kbVisible: false,
        kbInput: null
        // end keyboard ----------
      }
    },
    sockets: {
        connect: function () {
          console.log("IO Connected")
          this.$socket.emit("registerIsHmi", {})
        },
        show_message: function (payload) {
          let self = this
          if (payload.message && payload.message.length>0) {
            self.notify_message = payload.message
            self.message_color = 'red'
            if (payload.color) {self.message_color = payload.color}
            setTimeout(function () {
              self.notify_message = ""
            }, payload.timeout || 3000)
          }
        }
    },
    methods: {
      applyRotate () {
        if(this.rotate_angle==0){
          document.documentElement.style.transform = "rotate(0deg)"
          document.documentElement.style.webkitTransform  = "rotate(0deg)"
          document.documentElement.style.top = "0%"
          document.documentElement.style.left = "0%"

        }
        if(this.rotate_angle==180){
          document.documentElement.style.transform = "rotate(180deg)"
          document.documentElement.style.webkitTransform  = "rotate(180deg)"
          document.documentElement.style.top = "100%"
          document.documentElement.style.left = "100%"
        }
        if(this.rotate_angle==90){
          document.documentElement.style.transform = "rotate(90deg)"
          document.documentElement.style.webkitTransform  = "rotate(90deg)"
          document.documentElement.style.top = "0%"
          document.documentElement.style.left = "100%"
        }
        if(this.rotate_angle==-90){
          document.documentElement.style.transform = "rotate(-90deg)"
          document.documentElement.style.webkitTransform  = "rotate(-90deg)"
          document.documentElement.style.top = "100%"
          document.documentElement.style.left = "0%"
        }
      },
      gotoPath (path) {
        this.$router.push({path: path})
      },
      showPointControl (point) {
        this.setterPoint = point
        this.setterDialog = true
      },
      // keyboard --------------------------
      kbHide() {
        this.kbVisible = false
      },
      kbAccept (text) {
        console.log(`Keyboard text ${text}`)
        this.kbHide()
      },
      kbNext() {
        let inputs = document.querySelectorAll("input");
        let found = false;
        [].forEach.call(inputs, (item, i) => {
          if (!found && item == this.input && i < inputs.length - 1) {
            found = true;
            this.$nextTick(() => {
              inputs[i+1].focus()
            });
          }
        });
        if (!found) {
          // this.kbInput.blur()
          this.kbHide()
        }
      },	
      requestShowKeyboard(input, layout) {
        this.kbInput = input
        this.kbLayout = layout
        this.kbVisible = true
      }
      // end keyboard ----------------------
    },
    created() {
      const vm = this
      setTimeout(function(){
        vm.applyRotate()
      }, 2000)
      
      
      vm.$eventBus.$on('requestShowKeyboard', this.requestShowKeyboard)
      setTimeout(function(){
        vm.$router.push({ path:'/face' })
      }, 500)
    },
    destroyed() {
      const vm = this
      vm.$eventBus.$off('requestShowKeyboard', this.requestShowKeyboard)
    }
}
</script>

<style lang="scss">

  #v-app {
    width: 100vw;
    height: 100vh;
  }

  .landscape {
    width: 100vw !important;
    height: 100vh !important;
  }

  .portrait {
    width: 100vh !important;
    height: 100vw !important;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #666666;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #EEEEEE;
    box-sizing: border-box;
    overflow: hidden;
  }

  html {
    overflow: scroll;
    overflow-x: hidden;
    transform-origin: left top;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: absolute;
    // transform: rotate(-90deg);
    // -webkit-transform: rotate(-90deg);
    top: 100%;
    left: 0%;
  }

  

  ::-webkit-scrollbar {
      width: 0px;
      background: transparent;
  }
  ::-webkit-scrollbar-thumb {
      background: #CCCCCC;
  }


  #content {
    position: absolute;
    top: $header-height;
    right: 0px;
    left: 0px;
    bottom: 0px;
    padding: 0px;
    z-index: 1;
    background-color: #222222;
  }

  #divError {
    position: absolute;
    top: $header-height;
    right: 0px;
    left: 0px;
    bottom: 0px;
    padding: 0px;
    z-index: 9;
    background-color: #222222;
  }

  .headerHeight {
    min-height: $header-height;
    max-height: $header-height;
  }
</style>
